import {
    ChangeEvent, MouseEvent, ReactNode,
} from 'react';
import {
    AccountTree,
    DragIndicator,
    Error,
    Public,
    Delete,
    List,
} from '@mui/icons-material';
import {
    Tooltip,
    Grid,
    IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { DraggableProvided } from 'react-beautiful-dnd';

import Checkbox from 'src/components/common/inputs/Checkbox';
import {
    CardCell,
    CardRow,
} from 'src/components/common/CardTable';
import { getFilterData } from 'src/components/ManageGallery/Filters/FilterList/utils';
import { FILTER_TYPE } from 'src/constants';
import TextField from 'src/components/common/inputs/TextField';

interface PropTypes {
    draggable: DraggableProvided;
    editing?: boolean;
    filter: App.Filter;
    id: string;
    onHandleCollapsedChange: (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ) => void;
    onHandleHiddenChange: (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ) => void;
    onRemoveFilter: (event: MouseEvent<HTMLButtonElement>) => void;
    url: string;
    onHandleQuickviewFilterChange: (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ) => void;
    onHandleQuickviewOrderOverrideChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const renderCardCell = (child: ReactNode, detailsUrl?: string, className?: string): JSX.Element => (
    <CardCell align="center" className={className} to={detailsUrl}>
        {child}
    </CardCell>
);

const useStyles = makeStyles()((theme: Theme) => ({
    draggable: {
        cursor: 'grab',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    invisible: {
        visibility: 'hidden',
    },
    wrappable: {
        wordBreak: 'break-all',
    },
    qvOrderInput: {
        maxWidth: theme.spacing(10),
    },
}));

const FilterListItem = (props: PropTypes): JSX.Element => {
    const {
        draggable,
        editing,
        filter,
        id,
        onHandleCollapsedChange,
        onHandleHiddenChange,
        onRemoveFilter,
        onHandleQuickviewFilterChange,
        onHandleQuickviewOrderOverrideChange,
        url,
    } = props;
    const data = getFilterData(filter);
    const detailsUrl = data.detailsUrl && `${url}${data.detailsUrl || ''}`;
    const { classes } = useStyles();

    return (
        <CardRow
            hover
            role="button"
            key={`row${id}`}
            draggableProvided={draggable}
        >
            <CardCell className={classes.draggable}>
                <DragIndicator />
            </CardCell>
            {renderCardCell(data.sortOrder, detailsUrl)}
            {!detailsUrl && (
                <CardCell align="center" to={detailsUrl}>
                    <Tooltip title="This filter cannot be edited as it is shared by other galleries">
                        <Grid container justifyContent="center" spacing={1}>
                            {!detailsUrl && (
                                <Grid item>
                                    <Public fontSize="small" />
                                </Grid>
                            )}
                            <Grid item>
                                {data.type}
                            </Grid>
                        </Grid>
                    </Tooltip>
                </CardCell>
            )}
            {detailsUrl && (
                <CardCell align="center" to={detailsUrl}>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item>
                            {filter.type === FILTER_TYPE.taxonomy && (<AccountTree fontSize="small" />)}
                            {filter.type === FILTER_TYPE.private && (<List fontSize="small" />)}
                        </Grid>
                        <Grid item>
                            {data.type}
                        </Grid>
                    </Grid>
                </CardCell>
            )}
            {renderCardCell(data.name, detailsUrl, classes.wrappable)}
            {renderCardCell(data.title, detailsUrl, classes.wrappable)}
            {!editing && renderCardCell(data.collapsed ? 'yes' : 'no', detailsUrl)}
            {editing && renderCardCell(
                <Checkbox
                    editing
                    checked={data.collapsed}
                    onClick={(e) => onHandleCollapsedChange(e, !data.collapsed)}
                />,
            )}
            {!editing && renderCardCell(data.hidden ? 'yes' : 'no', detailsUrl)}
            {editing && renderCardCell(
                <Checkbox
                    editing={false}
                    checked={data.hidden}
                    onClick={(e) => onHandleHiddenChange(e, !data.hidden)}
                />,
            )}
            {!editing && renderCardCell((data.quickViewFilter !== null) ? 'yes' : 'no', detailsUrl)}
            {editing && renderCardCell(
                <Checkbox
                    editing
                    checked={!!data.quickViewFilter}
                    onClick={(e) => onHandleQuickviewFilterChange(e, !data.quickViewFilter)}
                />,
            )}
            {!editing && renderCardCell(data.quickViewFilter
                && (data.quickViewFilter.quickViewSortOrderOverride ?? `default (${data.quickViewFilter.quickViewSortOrder})`), detailsUrl)}
            {editing && renderCardCell(data.quickViewFilter
                ? (
                    <TextField
                        className={classes.qvOrderInput}
                        editing={editing}
                        id="qv-order-override-input"
                        type="number"
                        value={data.quickViewFilter.quickViewSortOrderOverride}
                        onChange={onHandleQuickviewOrderOverrideChange}
                    />
                ) : null)}
            {renderCardCell(
                (
                    <Error className={classes.invisible} color="error" />
                ), detailsUrl,
            )}
            {editing && (
                <CardCell>
                    <IconButton onClick={onRemoveFilter}>
                        <Delete color="error" />
                    </IconButton>
                </CardCell>
            )}
        </CardRow>
    );
};

export default FilterListItem;
