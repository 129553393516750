import { useContext, ChangeEvent, MouseEvent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';

import AddFiltersButtons from 'src/components/ManageGallery/Filters/FilterList/AddFiltersButtons';
import config from 'src/lib/config';
import FilterListItem from 'src/components/ManageGallery/Filters/FilterList/FilterListItem';
import Header from 'src/components/common/Header';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import ZeroState from 'src/components/common/ZeroState';
import { EditContext } from 'src/contexts/EditContext';
import { removeGalleryFilter, updateGalleryFilterOrder, updateGalleryFilter } from 'src/store/galleryData/filterStore';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from 'src/components/common/CardTable';
import { FILTER_TYPE, QUICKVIEW_FILTER_TYPE } from 'src/constants';
import { Help } from '@mui/icons-material';
import LabelTooltip from 'src/components/common/LabelTooltip';

type RemoveGalleryFilterEvent = (
    event: MouseEvent<HTMLButtonElement>,
) => void;

const useStyles = makeStyles()({
    scrollTable: {
        maxHeight: '100%',
        overflow: 'auto',
        textAlign: 'center',
        '&.MuiGrid-item': {
            paddingTop: 0,
        },
    },
    container: {
        height: '100%',
        maxHeight: '100%',
    },
});

const hiddenTooltipTitle = (
    <>
        <p>Hides the filter in the gallery left sidebar while still supporting it in QV.</p>
        <p>
            This should be left unchecked unless configuring an attribute filter as
            part of the Design Consolidation initiative.
        </p>
    </>
);

const qvPositionTooltipTitle = (
    <>
        <p>Optional. When empty, QV filter order will match Gallery filter order.</p>
        <p>Enter a value to override its position specifically for QuickView.</p>
    </>
);

const FilterList = (): JSX.Element => {
    const { classes } = useStyles();
    const { url } = useRouteMatch();
    const { editing } = useContext(EditContext);
    const { galleryData: { allFiltersById, gallery }, dispatch } = useContext(ManageGalleryContext);
    const filters = [...allFiltersById.byId.values()];

    const removeFilter = (id: string): RemoveGalleryFilterEvent => (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        dispatch(removeGalleryFilter(id));
    };

    const onDragEndFunc = (result: DropResult): void => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        dispatch(updateGalleryFilterOrder(source.index, destination.index));
    };

    const handleCollapsedChange = (filter: App.Filter) => (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        //  Collapsed field has a different name in category vs attribute
        if (filter.type === FILTER_TYPE.taxonomy) {
            dispatch(updateGalleryFilter({
                ...filter.filter,
                collapsed: value,
            }, filter.id));
        } else {
            dispatch(updateGalleryFilter({
                ...filter.filter,
                isCollapsed: value,
            }, filter.id));
        }
    };

    const onHandleHiddenChange = (filter: App.Filter) => (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        dispatch(updateGalleryFilter({
            ...filter.filter,
            hidden: value,
        }, filter.id));
    };

    const handleQuickviewFilterChange = (filter: App.Filter) => (
        event: MouseEvent<HTMLButtonElement>,
        value: boolean,
    ): void => {
        event.preventDefault();
        dispatch(updateGalleryFilter({
            ...filter.filter,
            quickViewFilter: (value) ? {
                quickViewSortOrder: filter.filter.sortOrder,
                quickViewFilterType: QUICKVIEW_FILTER_TYPE.RADIO,
            } as Services.Filters.QuickViewFilter : null,
        }, filter.id));
    };

    const handleQuickviewOrderOverrideChange = (filter: App.Filter) => (
        event: ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();
        const { value } = event.currentTarget;

        dispatch(updateGalleryFilter({
            ...filter.filter,
            quickViewFilter: {
                quickViewSortOrderOverride: parseInt(value, 10),
                quickViewFilterType: QUICKVIEW_FILTER_TYPE.RADIO,
            } as Services.Filters.QuickViewFilter,
        }, filter.id));
    };

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            spacing={4}
            wrap="nowrap"
        >
            {editing && config.features.AddFilter && (
                <Grid>
                    <AddFiltersButtons url={url} />
                </Grid>
            )}
            <Grid className={classes.scrollTable}>
                {!filters.length && (<ZeroState text="There are no Filters for this Gallery" />)}
                {!!filters.length && (
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center"><Header>Sort Order</Header></TableCell>
                                <TableCell align="center"><Header>Filter Type</Header></TableCell>
                                <TableCell align="center"><Header>Name</Header></TableCell>
                                <TableCell align="center"><Header>Title</Header></TableCell>
                                <TableCell align="center"><Header>Collapsed</Header></TableCell>
                                <TableCell align="center">
                                    <Header>
                                        Hidden
                                        <LabelTooltip title={hiddenTooltipTitle}>
                                            <Help sx={{ fontSize: '16px', marginLeft: '8px' }} color="primary" />
                                        </LabelTooltip>
                                    </Header>
                                </TableCell>
                                <TableCell align="center"><Header>QuickView Enabled</Header></TableCell>
                                <TableCell align="center">
                                    <Header>
                                        QuickView Position
                                        <LabelTooltip title={qvPositionTooltipTitle}>
                                            <Help sx={{ fontSize: '16px', marginLeft: '8px' }} color="primary" />
                                        </LabelTooltip>
                                    </Header>
                                </TableCell>
                                <TableCell />
                                {editing && (<TableCell />)}
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEndFunc}>
                            <Droppable key={`droppable${gallery.id}`} droppableId={gallery.id.toString()}>
                                {(provided): JSX.Element => (
                                    <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                        {filters.sort(
                                            (a, b) => a.filter.sortOrder - b.filter.sortOrder,
                                        ).map((filter, index) => (filter
                                            ? (
                                                <Draggable
                                                    key={`draggable${filter.id}`}
                                                    draggableId={filter.id.toString()}
                                                    index={index}
                                                    isDragDisabled={!editing}
                                                >
                                                    {(draggable): JSX.Element => (
                                                        <FilterListItem
                                                            draggable={draggable}
                                                            editing={editing}
                                                            filter={filter}
                                                            id={filter.id}
                                                            url={url}
                                                            onHandleQuickviewOrderOverrideChange={
                                                                handleQuickviewOrderOverrideChange(filter)
                                                            }
                                                            onHandleCollapsedChange={handleCollapsedChange(filter)}
                                                            onRemoveFilter={removeFilter(filter.id)}
                                                            onHandleQuickviewFilterChange={
                                                                handleQuickviewFilterChange(filter)
                                                            }
                                                            onHandleHiddenChange={onHandleHiddenChange(filter)}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                            : null))}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                )}
            </Grid>
        </Grid>
    );
};

export default FilterList;
